import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";

export default function MenuMobile() {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button variant="plain" {...bindTrigger(popupState)}>
            <MenuIcon />
          </Button>
          <Menu {...bindMenu(popupState)}>
            <MenuItem onClick={popupState.close}><Link to="/">Hem</Link></MenuItem>
            <MenuItem onClick={popupState.close}><Link to="/fastigheter">Våra fastigheter</Link></MenuItem>
            <MenuItem onClick={popupState.close}><Link to="/ledigt">Ledigt</Link></MenuItem>
            <MenuItem onClick={popupState.close}><Link to="/stadning">Städning</Link></MenuItem>
            <MenuItem onClick={popupState.close}><Link to="/kontakt">Kontakt</Link></MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}


{/* <div className="border-b-2 rounded-b-lg shadow-sm">
  <ul className="flex xl:flex-row md:flex-row flex-col list-none justify-center xl:gap-12 md:gap-12 m-6 text-slate-600 uppercase">
      <li className="hover:underline hover:underline-offset-4 cursor-pointer active:underline-offset-8 md:p-2 rounded-lg">
        <Link to="/">Hem</Link>
      </li>
    <li className="hover:underline hover:underline-offset-4 cursor-pointer active:underline-offset-8 p-2 rounded-lg">
      <Link to="/fastigheter">Våra fastigheter</Link>
    </li>
    <li className="hover:underline hover:underline-offset-4 cursor-pointer active:underline-offset-8 p-2 rounded-lg">
      <Link to="/ledigt">Ledigt</Link>
    </li>
    <li className="hover:underline hover:underline-offset-4 cursor-pointer active:underline-offset-8 p-2 rounded-lg">
      <Link to="/stadning">Städning</Link>
    </li>
    <li className="hover:underline hover:underline-offset-4 cursor-pointer active:underline-offset-8 p-2 rounded-lg">
      <Link to="/kontakt">Kontakt</Link>
    </li>
  </ul>
</div> */}