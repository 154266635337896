import FastigheterCard from "./FastigheterCard";
import Container from "../../UI/Container";
import { Link } from "react-router-dom";

export default function Fastigheter() {
  return (
    <>
      <Container>
        <h2 className="text-xl mb-8 font-semibold font-web tracking-wide uppercase">Våra Fastigheter</h2>
        <p className="">
          Vill du se vad vi har ledigt just nu? Klicka på{" "}
          <Link to="/ledigt">
            <b className="text-green-600 font-normal">ledigt</b>
          </Link>{" "}
          uppe i menyn.
        </p>
        <div className="flex flex-col justify-evenly items-center animate-fadein mt-8">
          <FastigheterCard
            img="https://www.forbes.com/advisor/wp-content/uploads/2022/10/condo-vs-apartment.jpeg.jpg"
            title="Ljungbyvägen 13 i Ljungby"
            subtitle="Charmiga nyrenoverade lägenheter"
            description="Ett lägenhetshus som är byggt 1972 som innehåller tre st 3rok och 5st 2rok. Tvättstuga i källaren och närhet till lokal affär."
          />
          <FastigheterCard
            img="https://timbeco.ee/sv/wp-content/uploads/sites/6/2019/10/Svavelstickan-front1-min-1140x641.jpg"
            title="Pruttvägen 11 i Oskarshamn"
            subtitle="4 lägenheter i bra läge"
            description="2st 4-rum och 2st 3-rum med moderna kök och nyrenoverade badrum."
          />
        </div>
      </Container>
    </>
  );
}
