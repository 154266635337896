import Container from "../../UI/Container";
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

export default function Kontakt() {
  return (
    <>
      <Container>
        <h2 className="text-xl font-semibold tracking-wide font-web uppercase">Kontakt</h2>
        <div className="">
          <div className="flex flex-col mt-8 text-base">
            <div className="">
              <p>HNO Fastigheter</p>
              <p>Redaregatan 20</p>
              <p>211 42 Malmö</p>
            </div>
            <p className="mt-2"><LocalPhoneIcon /> Telefonnummer: 0701229988 </p>
            <p className="m-4">
            <EmailIcon /> Email:{" "}
              <a
                href="mailto:dag@hno.se"
                className="text-gray-600 underline underline-offset-2" 
              >
                dag@hno.se
              </a>
            </p>
          </div>
          <div className="flex justify-center mb-8">
            {/* <div>
              <iframe className="xl:w-[600px] xl:h-[500px] md:w-[50vw] h-[400px]"
                title="map"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=Regementsgatan%2020%20malm%C3%B6&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
              ></iframe>
            </div> */}
          </div>
        </div>
        <div className=""></div>
      </Container>
    </>
  );
}
