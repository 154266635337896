import ledigtPicture from "../assets/ledigt.jpg";
import kontakt from "../assets/kontakt.jpg";
import cleaning from "../assets/housework.jpg";
import house from "../assets/house.jpg";
import Container from "../UI/Container";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      <Container>
        <div className="mt-0">
          <h2 className="font-web text-xl font-bold tracking-wide uppercase">
            Välkommen till HNO Förvaltning
          </h2>
          <p className="font-roboto mt-2">Denna text kommer bytas ut till något mer intressant.</p>
          <p className="m-4">
            Här kommer komma mer text som beskriver HNO och verksamheten.
          </p>
        </div>
        <div className="flex flex-col gap-10">
          <div className="flex flex-col xl:flex-row justify-center items-center gap-10">
            <Link to="/ledigt">
              <div className="flex justify-center items-center max-h-[240px] max-w-[400px] overflow-hidden self-center group">
                <div className="absolute text-white text-5xl z-10 group-hover:text-black font-web">
                  LEDIGT
                </div>
                <img
                  src={ledigtPicture}
                  alt="green"
                  className="group-hover:contrast-100 group-hover:opacity-50 "
                />
              </div>
            </Link>
            <Link to="/fastigheter">
              <div className="flex justify-center items-center max-h-[240px] max-w-[400px] overflow-hidden self-center group">
                <div className="absolute text-white text-4xl z-10 group-hover:text-black font-web">
                  VÅRA <br></br>FASTIGHETER
                </div>
                <img
                  src={house}
                  alt="green"
                  className="group-hover:contrast-100 group-hover:opacity-50 contrast-50"
                />
              </div>
            </Link>
          </div>
          <div className="flex flex-col xl:flex-row justify-center items-center gap-10">
            <Link to="/stadning">
              <div className="flex justify-center items-center max-h-[240px] max-w-[400px] overflow-hidden self-center group">
                <div className="absolute text-white text-5xl z-10 group-hover:text-black font-web">
                  STÄDSERVICE
                </div>
                <img
                  src={cleaning}
                  alt="green"
                  className="group-hover:contrast-100 group-hover:opacity-50 contrast-50"
                />
              </div>
            </Link>
            <Link to="/kontakt">
              <div className="flex justify-center items-center max-h-[240px] max-w-[400px] overflow-hidden self-center group">
                <div className="absolute text-white text-5xl z-10 group-hover:text-black font-web">
                  KONTAKT
                </div>
                <img
                  src={kontakt}
                  alt="green"
                  className="group-hover:contrast-100 group-hover:opacity-50 contrast-50"
                />
              </div>
            </Link>
          </div>
        </div>
      </Container>
    </>
  );
}
