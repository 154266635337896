//Components
import Header from "./components/Header";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Fastigheter from "./components/fastigheter/Fastigheter";
import Ledigt from "./components/ledigt/Ledigt";
import Stadning from "./components/stadning/Stadning";
import Kontakt from "./components/kontakt/Kontakt";
import Admin from "./components/admin/Admin";
//import Login from "./components/admin/Login";
import LedigtInfo from "./components/ledigt/LedigtInfo";
import SignIn from "./components/admin/SignIn";
import AuthDetails from "./utility/AuthDetails";

//Router dom
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from "react";
import AdminEditObject from "./components/admin/AdminEditObject";



function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(JSON.parse(localStorage.getItem("loggedIn")))
  const [lediga, setLediga] = useState([]);

  return (
    <div className="text-center font-roboto">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/fastigheter" element={<Fastigheter />} />
          <Route path="/ledigt" element={<Ledigt lediga={lediga} setLediga={setLediga}/>} />
          <Route path="/stadning" element={<Stadning />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/admin" element={isLoggedIn ? <Admin setIsLoggedIn={setIsLoggedIn}/> : <SignIn setIsLoggedIn={setIsLoggedIn}/>} />
          <Route path="/ledigt/:id" element={<LedigtInfo lediga={lediga} setLediga={setLediga}/>}/>
          <Route path="/admin/:id" element={isLoggedIn ? <AdminEditObject /> : <SignIn setIsLoggedIn={setIsLoggedIn}/>}/>
        </Routes>
        <AuthDetails />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
