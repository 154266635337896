import { useParams, useLocation, Link } from "react-router-dom";
import "@firebase/firestore";
import "@firebase/storage";
import db from "../../utility/firebase";
import {
  getStorage,
  ref,
  deleteObject,
  listAll,
  getDownloadURL,
} from "firebase/storage";
import DeleteIcon from "@mui/icons-material/Delete";
import { doc, updateDoc } from "firebase/firestore";

import { useState, useEffect } from "react";
import UploadFile from "./UploadFile";
import UploadMainImg from "./UploadMainImg";
import Container from "../../UI/Container";

const AdminEditObject = () => {
  const params = useParams();
  const location = useLocation();
  const obj = location.state;

  const [thisObject, setThisObject] = useState(obj);
  const [thisImages, setThisImages] = useState([]);
  const [imagesList, setImagesList] = useState([]);
  const [images, setImages] = useState([]);

  const [updateMessage, setUpdateMessage] = useState("");

  const storage = getStorage();

  const handleUpdate = async (id, obj) => {
    const objectDoc = doc(db, "ledigt", id);
    await updateDoc(objectDoc, obj);
    setUpdateMessage("Ändrat!");
    setTimeout(()=>{setUpdateMessage("")}, 3000)
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setThisObject((prevObj) => ({ ...prevObj, [name]: value }));
  };

  const handleEdit = (e) => {
    e.preventDefault();
    handleUpdate(thisObject.id, thisObject);
  };

  //Get all images from storage and store them in images:
  useEffect(() => {
    setImages([]);
    let tempArray = [];
    const listRef = ref(storage, `images/${obj.id}/`);
    // Find all the prefixes and items.
    listAll(listRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          // All the items under listRef.
          getDownloadURL(itemRef).then((url) => {
            setImages((prev) => [...prev, url]);
            tempArray.push(url);
          });
        });
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        console.error("error in listAll: ", error);
      });
    setImagesList((prev) => tempArray);
  }, []);

  const testList = Object.keys(imagesList);

  //setting thisImages to all images from this lediga.id folder:
  useEffect(() => {
    testList.map((index) => {
      if (index === params.id) {
        setThisImages([...[imagesList[index]]]);
      }
    });
  }, [imagesList, params]);

  const handleImgDelete = (e) => {
    const fileURL = e.target.src;
    const storageRef = ref(storage, fileURL);

    if (storageRef) {
      deleteObject(storageRef)
        .then(() => {
          console.log("This file has been deleted: ", fileURL);
          alert("File successfully deleted!");
          window.location.reload(false);
        })
        .catch((error) => {
          console.error("Something wrong with delete... ", error);
        });
    }
    console.log("Image got deleted!");
  };

  return (
    <>
      <Container>
        <div>
          <div className="flex flex-col">
            <div className="flex flex-col items-center">
              <div className="font-bold text-lg p-4">EDITERA OBJEKT</div>
              <p className="text-sm">
                Klicka på bilden för att ta ta bort den.
              </p>
              <div className="flex flex-row">
                {imagesList?.map((img) => (
                  <div
                    key={img}
                    className="border-2 rounded-lg hover:border-red-600 group cursor-pointer"
                  >
                    <div
                      className="p-2"
                      onClick={handleImgDelete}
                      onTouchEnd={handleImgDelete}
                    >
                      <DeleteIcon className="invisible group-hover:visible text-red-600 absolute z-10 group-focus:text-red-400" />
                      <img
                        className="group-hover:opacity-50"
                        src={img}
                        alt="lägenhet"
                        width={80}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col xl:flex-row justify-between">
                <div className="border-2 p-4 m-2 rounded-xl">
                  <UploadMainImg thisObjectId={obj.id} />
                </div>
                <div className="border-2 p-4 m-2 rounded-xl">
                  <UploadFile thisObjectId={obj.id} />
                </div>
              </div>

              <form onSubmit={handleEdit} className="">
                <div className="flex flex-col p-8 text-left">
                  <div className="mt-2 flex flex-col">
                    <label className="font-bold">Titel: </label>
                    <input
                      className="border-2 p-[2px]"
                      name="title"
                      defaultValue={thisObject.title}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mt-2 flex flex-col">
                    <label className="font-bold">Undertitel: </label>
                    <input
                      className="border-2 p-[2px]"
                      name="subtitle"
                      defaultValue={thisObject.subtitle}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mt-4 mb-4 flex flex-col">
                    <div className="flex xl:flex-row flex-col xl:gap-8 gap-2">
                      <div className="flex flex-col">
                        <label className="font-bold">Hyra:</label>
                        <input
                          className="border-2 p-[2px] w-24"
                          name="rent"
                          defaultValue={thisObject.rent}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="flex flex-col">
                        <label className="font-bold">Kvm:</label>
                        <input
                          className="border-2 p-[2px] w-24"
                          name="size"
                          defaultValue={thisObject.size}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="flex flex-col">
                        <label className="font-bold">Antal rum:</label>
                        <input
                          className="border-2 p-[2px] w-24"
                          name="rooms"
                          defaultValue={thisObject.rooms}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 flex flex-col">
                    <label className="font-bold">Beskrivning: </label>
                    <textarea
                      className="border-2 p-[2px]"
                      name="description"
                      defaultValue={thisObject.description}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mt-2 flex flex-col">
                    <label className="font-bold">
                      När är den tillgänglig för inflytt?:{" "}
                    </label>
                    <input
                      className="border-2 p-[2px]"
                      name="available"
                      defaultValue={thisObject.available}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mt-2 flex flex-col">
                    <label className="font-bold">Telefonnummer:</label>
                    <input
                      className="border-2 p-[2px]"
                      name="phone"
                      defaultValue={thisObject.phone}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mt-2 flex flex-col">
                    <label className="font-bold">E-mail:</label>
                    <input
                      className="border-2 p-[2px]"
                      name="email"
                      defaultValue={thisObject.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="mt-6">För att länkas till google maps:</div>
                    <div className="flex flex-col xl:flex-row xl:justify-between">
                      <div className="mt-2 flex flex-col">
                        <label className="font-bold">Gata: </label>
                        <input
                          className="border-2 p-[2px] xl:w-[400px]"
                          name="street"
                          defaultValue={thisObject.street}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mt-2 flex flex-col">
                        <label className="font-bold">Nummer: </label>
                        <input
                          className="border-2 p-[2px]"
                          name="streetnumber"
                          defaultValue={thisObject.streetnumber}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="mt-2 flex flex-col">
                      <label className="font-bold">Stad: </label>
                      <input
                        className="border-2 p-[2px]"
                        name="town"
                        defaultValue={thisObject.town}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  {updateMessage && (
                    <div className="text-green-700">{updateMessage}</div>
                  )}
                </div>
                <div className="m-4 flex flex-row justify-evenly">
                  <input
                    type="submit"
                    value="Uppdatera"
                    className="btn btn-blue cursor-pointer"
                  />
                  <Link to="/admin">
                    <button className="btn border-2 rounded-lg hover:bg-gray-200">
                      Tillbaka
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default AdminEditObject;
