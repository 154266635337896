import { useEffect, useState } from "react";
import db, { storage } from "../../utility/firebase";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import AdminList from "./AdminList";
import AddNewObject from "./AddNewObject";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";

export default function Admin(props) {
  const [lediga, setLediga] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  //const [images, setImages] = useState([]);
  const [imagesList, setImagesList] = useState({});

  const handleLogout = () => {
    props.setIsLoggedIn(false);
    localStorage.setItem("loggedIn", false);
  };

  const ledigaCollectionRef = collection(db, "ledigt");

  //Fetching
  useEffect(() => {
    const getLediga = async () => {
      const data = await getDocs(ledigaCollectionRef);
      setLediga(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getLediga();
  }, []);

  const handleDelete = async (id) => {
    try {
      const docRef = doc(db, "ledigt", id);
      await deleteDoc(docRef);
      window.location.reload();
    } catch (e) {
      console.error("Error deleting document: ", e);
    }
  };

  //Get all images from storage and store them in images:
  useEffect(() => {
    //setImages([]);
    for (let i = 0; lediga.length > i; i++) {
      let tempArray = [];
      let listIndex = lediga[i]?.id;
      const listRef = ref(storage, `images/${lediga[i]?.id}/`);
      // Find all the prefixes and items.
      listAll(listRef)
        .then((res) => {
          res.items.forEach((itemRef) => {
            // All the items under listRef.
            getDownloadURL(itemRef).then((url) => {
              //setImages((prev) => [...prev, url]);
              tempArray.push(url);
              // if (url.includes("main.jpg")) {
              //   console.log("fileExtend in the images: ", url);
              //   setImagesList((prev) => ({ ...prev, [mainImg]: url }))
              // }
            });
          });
        })
        .catch((error) => {
          console.error("error in listAll: ", error);
        });
      setImagesList((prev) => ({ ...prev, [listIndex]: tempArray }));
    }
  }, [lediga]);

  return (
    <>
      <h2 className="text-xl mb-8 font-bold tracking-wide">ADMIN</h2>
      {showAdd && (
        <div className="">
          <AddNewObject setShowAdd={setShowAdd} />
        </div>
      )}
      <div className="m-8">
        {!showAdd && (
          <button
            className="btn btn-blue"
            onClick={() => {
              setShowAdd(true);
            }}
          >
            Klicka här för att lägga till objekt!
          </button>
        )}
      </div>
      <div className="flex flex-col xl:m-auto m-4 border-2 border-blue-200 rounded-lg xl:max-w-[60%]">
        {lediga.map((obj) => (
          <div
            className="flex xl:flex-row flex-col items-center xl:justify-between border-2 p-4 gap-4 xl:gap-0  odd:bg-gray-200"
            key={obj.id}
          >
            <AdminList obj={obj} />
            <div className="flex flex-row items-center gap-4">
              <button
                className="btn flex items-center border-2 bg-red-600 text-white hover:bg-red-700"
                onClick={() => handleDelete(obj.id)}
              >
                <DeleteIcon />
                Delete
              </button>
              <Link to={`/admin/${obj.id}`} state={obj}>
                <button className="btn btn-blue w-[100px]">
                  <EditIcon />
                  Edit
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
      <div className="mb-[100px] mt-8">
        <button className="btn btn-blue" onClick={handleLogout}>
          Logga ut
        </button>
      </div>
    </>
  );
}
