import Card from "../../UI/Card";

export default function FastigheterCard(props) {
  return (
    <>
      <Card>
        <div className="flex xl:flex-row flex-col border-[1px]">
          <div className="xl:max-w-[50%] max-h-[300px] md:max-h-[100%]">
            <img className="" src={props.img} alt="objekt" />
          </div>
          <div className="flex flex-col text-left p-4">
            <div className="text-lg font-bold font-web">{props.title}</div>
            <div className="text-gray-500 italic">{props.subtitle}</div>
            <div className="mt-8">{props.description}</div>
          </div>
        </div>
      </Card>
    </>
  );
}
