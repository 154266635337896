import { useState } from "react";
import db from "../../utility/firebase";
import { collection, addDoc } from "firebase/firestore";

export default function AddNewObject(props) {
  const [newObject, setNewObject] = useState({
    img: "main.jpg",
    title: "",
    subtitle: "",
    description: "",
    available: "",
    phone: "",
    email: "",
  });

  //Add new document to the firestore database
  const handleAddNew = async (e) => {
    e.preventDefault();

    try {
      const docRef = await addDoc(collection(db, "ledigt"), newObject);
      console.log("New document was written if ID: ", docRef.id);
      window.location.reload();
      setNewObject({
        img: "main.jpg",
        title: "",
        subtitle: "",
        description: "",
        available: "",
        phone: "",
        email: "",
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewObject((prevObj) => ({ ...prevObj, [name]: value }));
  };

  const hideHandler = () => {
    setTimeout(props.setShowAdd(false), 2000);
  };

  return (
    <>
      <div className="animate-fadein flex flex-col justify-center items-center text-left border-2 max-w-[95vw] xl:max-w-fit m-auto rounded-lg p-4 pl-6 pr-6">
        <h3 className="font-bold m-2">Lägg till nytt objekt: </h3>
        <form>
          <div className="flex flex-col min-w-[90vw] xl:min-w-fit">
            {/* <div className="mt-2 flex flex-col w-[400px]">
              <label>Bildlänk: </label>
              <input className="border-2 p-[2px]" name="img" onChange={handleChange} />
            </div> */}
            <div className="mt-2 flex flex-col">
              <label>Titel: </label>
              <input
                className="border-2 p-[2px]"
                name="title"
                onChange={handleChange}
              />
            </div>
            <div className="mt-2 flex flex-col">
              <label>Undertitel: </label>
              <input
                className="border-2 p-[2px]"
                name="subtitle"
                onChange={handleChange}
              />
            </div>
            <div className="mt-4 mb-4 flex flex-col">
              <div className="flex flex-col xl:flex-row gap-8">
                <div className="flex flex-col">
                  <label>Hyra:</label>
                  <input
                    className="border-2 p-[2px]"
                    name="rent"
                    onChange={handleChange}
                  />
                </div>
                <div className="flex flex-col">
                  <label>Kvm:</label>
                  <input
                    className="border-2 p-[2px]"
                    name="size"
                    onChange={handleChange}
                  />
                </div>
                <div className="flex flex-col">
                  <label>Antal rum:</label>
                  <input
                    className="border-2 p-[2px]"
                    name="room"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="mt-2 flex flex-col">
              <label>Beskrivning: </label>
              <textarea
                className="border-2 p-[2px]"
                name="description"
                onChange={handleChange}
              />
            </div>
            <div className="mt-2 flex flex-col">
              <label>När är den tillgänglig för inflytt?: </label>
              <input
                className="border-2 p-[2px]"
                name="available"
                onChange={handleChange}
              />
            </div>
            <div className="mt-2 flex flex-col">
              <label>Telefonnummer: </label>
              <input
                className="border-2 p-[2px]"
                name="phone"
                onChange={handleChange}
              />
            </div>
            <div className="mt-2 flex flex-col">
              <label>E-mail: </label>
              <input
                className="border-2 p-[2px]"
                name="email"
                onChange={handleChange}
              />
            </div>
            <div>
              <div className="mt-6">För att länkas till google maps:</div>
              <div className="flex flex-col xl:flex-row xl:justify-between">
                <div className="mt-2 flex flex-col">
                  <label>Gata: </label>
                  <input
                    className="border-2 p-[2px] xl:w-[400px]"
                    name="street"
                    onChange={handleChange}
                  />
                </div>
                <div className="mt-2 flex flex-col">
                  <label>Nummer: </label>
                  <input
                    className="border-2 p-[2px]"
                    name="streetnumber"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="mt-2 flex flex-col">
                <label>Ort: </label>
                <input
                  className="border-2 p-[2px]"
                  name="town"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 flex flex-row justify-between">
            <button onClick={handleAddNew} className="btn btn-blue">
              LÄGG TILL
            </button>
            <button onClick={hideHandler} className="btn btn-slate text-sm m-2">
              GÖM
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
