import { useState, useEffect } from "react";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../utility/firebase";
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { Link } from "react-router-dom";

export default function AdminList({ obj, to }) {
  const [mainImg, setMainImg] = useState("");

  useEffect(() => {
    getDownloadURL(ref(storage, `images/${obj.id}/main.jpg`))
      .then((url) => {
        setMainImg(url);
      })
      .catch((error) => {
        return console.error("what: ", error);
      });
  }, [obj]);

  return (
    <>
      <div className="xl:w-[75%]">
        <div className="flex flex-row font-bold items-center justify-between">
          <div>{obj.title}</div>
          <div className="flex items-center ml-8">
            {mainImg ? <img
                className="h-10"
                src={mainImg}
                alt="small pic"
              />
            : <NoPhotographyIcon />}
          </div>
        </div>
      </div>
    </>
  );
}
