import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../../utility/firebase";

export default function SignIn(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);

  const signIn = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        //console.log("userCredential: ", userCredential._tokenResponse.idToken);
        localStorage.setItem("loggedIn", true);
        setShowError(false);
        props.setIsLoggedIn(true);
      })
      .catch((error) => {
        console.error(error);
        setShowError(true);
      });
  };

  return (
    <div className="flex justify-center flex-col items-center">
      <form onSubmit={signIn}>
        <h1>Logga in</h1>
        <div className="flex xl:flex-row flex-col max-w-[60vw] items-center gap-4">
          <input
            className="border-2 p-1 rounded-md xl:mr-2 xl:mt-0 mt-4"
            type="email"
            placeholder="Epost"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="border-2 p-1 rounded-md xl:ml-2"
            type="password"
            placeholder="Lösenord"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit" className="btn btn-blue xl:ml-4">
            Logga in
          </button>
        </div>
      </form>
      {showError && (
        <div className="text-red-600 mt-6">Fel epost eller lösenord</div>
      )}
    </div>
  );
}
