import { useState } from "react";
import { storage } from "../../utility/firebase";
import { ref, uploadBytes } from "firebase/storage";
import { Upload } from "@mui/icons-material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Alert from "@mui/material/Alert";

export default function UploadFile({ thisObjectId }) {
  const [file, setFile] = useState(null);
  const [errorFile, setErrorFile] = useState("");
  const [thisId, setThisId] = useState(thisObjectId);

  const [uploadSuccessfull, setUploadSuccessfull] = useState(false);

  const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
  //const imageListRef = ref(storage, `images/${thisId}/`);

  const handleFileChange = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
      setErrorFile("");
    } else {
      setFile(null);
      setErrorFile(
        "Vänligen välj en bildfil i formatet .png, .jpg eller .jpeg"
      );
    }
  };

  const handleUploadImage = () => {
    if (file == null) return;
    const imageRef = ref(storage, `images/${thisId}/${file.name}`);
    uploadBytes(imageRef, file).then(() => {
      // alert("Hurra! Uppladdningen lyckades!");
      setUploadSuccessfull(true);
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  return (
    <>
      <div className="mt-4">
        <div className="flex flex-col justify-center items-center">
          <form>
            <AddPhotoAlternateIcon />
            <label>
              Ladda upp bild till bildlistan <br></br>
              <p className="text-sm">(endast .jpg .jpeg och .png)</p>
            </label>

            <input
              type="file"
              onChange={handleFileChange}
              className="block w-full text-sm text-slate-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-blue-50 file:text-blue-600
                hover:file:bg-blue-100 m-2"
            />
          </form>
        </div>
        {errorFile && <div className="mt-4 text-red-500">{errorFile}</div>}
        {/* {file && <div className="">{file.name}</div>} */}
        {/* {file && <ProgressBar file={file} setFile={setFile}/>} */}
        <button
          onClick={handleUploadImage}
          disabled={!file}
          className="btn btn-blue m-2 disabled:bg-blue-300"
        >
          Ladda upp bild! <Upload />
        </button>
        {uploadSuccessfull && (
          <Alert severity="success" className="flex justify-center">
            Uppladdning lyckades!
          </Alert>
        )}
      </div>
    </>
  );
}
