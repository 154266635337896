import Card from "../../UI/Card";
import HomeIcon from "@mui/icons-material/Home";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { useState, useEffect } from "react";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../utility/firebase";
import { Link } from "react-router-dom";

export default function LedigCard(props) {
  const [mainImg, setMainImg] = useState("");

  useEffect(() => {
    getDownloadURL(ref(storage, `images/${props.id}/main.jpg`))
      .then((url) => {
        setMainImg(url);
      })
      .catch((error) => {
        if (error.code === "storage/object-not-found")
          return console.warn("main-image not found, in LedigCard");
      });
  }, [props]);

  return (
    <>
      <div>
        <Card>
          <Link to={props.to} className="w-[100%]">
            <div className="flex xl:flex-row flex-col border-[1px] rounded-lg hover:border-green-600 xl:w-[100%] xl:h-[260px]">
              <div className="xl:w-[50%]">
                {mainImg ? (
                  <img
                    className="w-[100%] h-[100%] xl:rounded-tl-lg xl:rounded-bl-lg xl:rounded-t-none rounded-t-lg"
                    src={mainImg}
                    alt="objekt"
                  />
                ) : (
                  <div className="h-[260px] flex justify-center items-center">
                    <ImageNotSupportedIcon />
                  </div>
                )}
              </div>
              <div className="flex flex-col text-left p-4 justify-between xl:w-[50%]">
                <div className="text-lg font-web font-semibold">{props.title}</div>
                {props.subtitle && (
                  <div className="text-gray-500 mb-2 flex">
                    <HomeIcon style={{ color: "green" }} />
                    {props.subtitle}
                  </div>
                )}
                <div className="flex flex-row gap-12 font-semibold">
                  <div>{props.rent && <>{props.rent} kr/mån</>}</div>
                  <div>{props.size && <>{props.size} kvm</>}</div>
                  <div>{props.rooms && <>{props.rooms} rum</>}</div>
                </div>
                <div className="mt-4 overflow-hidden max-h-[20vh] md:max-h-[40vh] text-slate-400">
                  {props.description}
                </div>
                <div className="mt-2 flex flex-row gap-2">
                  <p className="font-bold">Tillträde:</p>{" "}
                  {props.available ? (
                    <>{props.available}</>
                  ) : (
                    "Ingen information"
                  )}
                </div>
              </div>
            </div>
          </Link>
        </Card>
      </div>
    </>
  );
}
