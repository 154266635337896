import logo from "../assets/hno-logo-white-trans.png";

export default function Footer() {
  return (
    <>
      <div className="fixed bottom-0 left-0 h-[60px] w-full bg-gray-800 flex flex-row justify-between text-gray-400 items-center z-20">
        <div className="flex items-end">
          <img className="md:h-[60px] h-[50px] p-2" src={logo} alt="footer logo" />
          {/* <div className="self-center xl:ml-8 md:ml-4">
            <Link to="/admin">Admin</Link>
          </div> */}
        </div>
        <div className="mr-4">
          <div className="font-thin text-[10px] md:text-xs">
            <p>Hemsida av: <br></br>Johan Aldgård</p>
            <p>johan@aldgard.se</p>
          </div>
        </div>
      </div>
    </>
  );
}
