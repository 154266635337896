import LedigtList from "./LedigtList";
import Container from "../../UI/Container";

export default function Ledigt({ lediga, setLediga }) {
  return (
    <>
      <Container>
        <h2 className="text-xl mb-8 font-semibold font-web tracking-wide uppercase">Lediga lägenheter</h2>
        <div className="animate-fadein">
          <LedigtList lediga={lediga} setLediga={setLediga} />
        </div>
      </Container>
    </>
  );
}
