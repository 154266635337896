import Container from "../../UI/Container";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import db, { storage } from "../../utility/firebase";
import { collection, getDocs } from "firebase/firestore";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import LedigtInfoModal from "./LedigtInfoModal";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

export default function LedigtInfo() {
  const params = useParams();

  const [allObjects, setAllObjects] = useState([]);
  const [thisImages, setThisImages] = useState([]);
  const [images, setImages] = useState(null);
  const [imagesList, setImagesList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const ledigaCollectionRef = collection(db, "ledigt");
  const [mainImg, setMainImg] = useState("");

  useEffect(() => {
    getDownloadURL(ref(storage, `images/${params.id}/main.jpg`))
      .then((url) => {
        setMainImg(url);
      })
      .catch((error) => {
        if(error.code === "storage/object-not-found")
        return console.warn("main-image not found, in LedigCard")
      });
  }, [params.id]);

  useEffect(() => {
    const getLediga = async () => {
      const data = await getDocs(ledigaCollectionRef);
      setAllObjects(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    getLediga();
  }, []);

  //Get all images from storage and store them in images:
  useEffect(() => {
    setImages([]);
    for (let i = 0; allObjects.length > i; i++) {
      let tempArray = [];
      let listIndex = allObjects[i]?.id;
      const listRef = ref(storage, `images/${allObjects[i]?.id}/`);
      // Find all the prefixes and items.
      listAll(listRef)
        .then((res) => {
          res.items.forEach((itemRef) => {
            // All the items under listRef.
            getDownloadURL(itemRef).then((url) => {
              setImages((prev) => [...prev, url]);
              tempArray.push(url);
            });
          });
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.error("error in listAll: ", error);
        });
      setImagesList((prev) => ({ ...prev, [listIndex]: tempArray }));
    }
  }, [allObjects]);

  const testList = Object.keys(imagesList);

  //setting thisImages to all images from this lediga.id folder:
  useEffect(() => {
    testList.map((index) => {
      if (index === params.id) {
        setThisImages([...[imagesList[index]]]);
      }
    });
  }, [imagesList, params]);

  return (
    <>
      <Container>
        <div className="w-screen flex justify-center">
          {isModalOpen && thisImages[0]?.length > 0 && (
            <LedigtInfoModal
              images={thisImages[0]}
              setIsModalOpen={setIsModalOpen}
            />
          )}
        </div>
        {/* <div className="flex flex-row self-center border-2 justify-around w-[800px] items-end">
          {thisImages[0]?.map((img, index) => (
            <div className="animate-fadein" key={index}>
              <img src={img} width={80} />
            </div>
          ))}
        </div> */}
        {allObjects?.map(
          (obj) =>
            obj.id === params.id && (
              <div
                className="flex flex-col border-2 rounded-lg xl:w-[40vw] md:w-[70vw] w-[330px] m-auto mb-[40px]"
                key={obj.id}
              >
                <div className="xl:max-w-[100%]">
                  {mainImg && <img
                    className="xl:w-[100%]"
                    src={mainImg}
                    alt="this object"
                  />}
                </div>
                <div className="flex flex-row justify-between p-4">
                  <div className="flex flex-col items-start">
                    <div className="font-bold p-2 text-lg font-web tracking-wide">{obj.title}</div>
                    <div>{obj.street && obj.streetnumber && obj.town && <a href={`https://www.google.se/maps/place/${obj.street}+${obj.streetnumber},+${obj.town}/`} target="_blank" rel="noreferrer"><button className="btn btn-blue ml-2">Länk till karta</button></a>}</div>
                  </div>
                  <div>
                    <div>{obj.rent} kr/mån</div>
                    <div className="">
                      <button disabled={!mainImg} className="btn btn-blue disabled:bg-blue-200" onClick={() => setIsModalOpen(true)}>
                        Fler bilder
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <hr className="m-4"></hr>
                </div>
                <div className="flex xl:flex-row flex-col xl:p-4 xl:gap-8 justify-between">
                  <div className="pl-8 pr-8">
                    {obj.description}
                  </div>
                  <div>
                    <dl className="text-left xl:w-40 gap-4 flex flex-col border-y-2 p-4 xl:mr-4 items-center text-sm mt-4 xl:mt-0">
                      <div className="flex flex-row">
                        <dt className="font-bold">Storlek: </dt>
                        <dd className="">{obj.size} kvm</dd>
                      </div>
                      <div className="flex flex-row gap-2">
                        <dt className="font-bold">Antal rum: </dt>
                        <dd className="">{obj.rooms}</dd>
                      </div>
                    </dl>
                    <div className="flex xl:justify-between justify-center p-4 gap-2">
                      <LocalPhoneIcon className="text-blue-700" /> {obj.phone}
                    </div>
                  </div>
                </div>
              </div>
            )
        )}
      </Container>
    </>
  );
}
