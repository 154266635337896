import {useState, useEffect } from "react";
import LedigCard from "./LedigCard";
import db, {storage} from "../../utility/firebase";
import { collection, getDocs } from "firebase/firestore";

import Container from "../../UI/Container";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

import no_available from "../../assets/no_available.jpg"

export default function LedigtList({ lediga, setLediga }) {
  const [imagesList, setImagesList] = useState({});

  const ledigaCollectionRef = collection(db, "ledigt");

  useEffect(() => {
    const getLediga = async () => {
      const data = await getDocs(ledigaCollectionRef);
      setLediga(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    getLediga();
  }, []);

  //Get all images from storage and store them in images:

  useEffect(() => {
    for (let i = 0; lediga.length > i; i++) {
      let tempArray = [];
      let listIndex = lediga[i]?.id;
      const listRef = ref(storage, `images/${lediga[i]?.id}/`);
      // Find all the prefixes and items.
      listAll(listRef)
        .then((res) => {
          res.prefixes.forEach((folderRef) => {
            // All the prefixes under listRef.
            // You may call listAll() recursively on them.
          });
          res.items.forEach((itemRef) => {
            // All the items under listRef.
            getDownloadURL(itemRef).then((url) => {
              tempArray.push(url);
            });
          });
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.error("error in listAll: ", error);
        });
      setImagesList((prev) => ({ ...prev, [listIndex]: tempArray }));
    }
  }, [lediga]);

  return (
    <>
      <Container>
        <div>
          {lediga ? (
            lediga.map((obj) => (
              <div key={obj.id}>
                  <LedigCard
                    to={`/ledigt/${obj.id}`}
                    img={obj.img}
                    title={obj.title}
                    subtitle={obj.subtitle}
                    description={obj.description}
                    rent={obj.rent}
                    size={obj.size}
                    rooms={obj.rooms}
                    available={obj.available}
                    phone={obj.phone}
                    email={obj.email}
                    id={obj.id}
                  />
              </div>
            ))
          ) : (
            <div>
              Det finns tyvärr inga lediga lägenheter just nu...{" "}
              <SentimentVeryDissatisfiedIcon />
              <div className="flex justify-center mt-4">
                <img src={no_available} alt="inga lediga :(" className="w-[300px]"/>
                </div>
            </div>
          )}
        </div>
      </Container>
    </>
  );
}
