import logo from "../assets/hno-logo-trans.png";
import MenuMobile from "./MenuMobile";

import { NavLink } from "react-router-dom";
// bg-gradient-to-b from-slate-300 to-slate-50
export default function Header() {
  let width = window.innerWidth;
  // const hamburger = width > 400 ? "" : <MenuMobile />

  const menu =
    width > 767 ? (
      <div className="border-b-2 rounded-b-lg shadow-sm tracking-wide font-web">
        <ul className="flex xl:flex-row md:flex-row flex-col list-none justify-center xl:gap-12 md:gap-12 m-2 text-slate-600 uppercase">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "underline underline-offset-4 hover:underline hover:underline-offset-4 cursor-pointer active:underline-offset-8 md:p-2 rounded-lg transition-all"
                : "hover:underline hover:underline-offset-4 cursor-pointer active:underline-offset-8 md:p-2 rounded-lg transition-all"
            }
          >
            <li className="">Hem</li>
          </NavLink>
          <NavLink
            to="/fastigheter"
            className={({ isActive }) =>
              isActive
                ? "underline underline-offset-4 hover:underline hover:underline-offset-4 cursor-pointer active:underline-offset-8 md:p-2 rounded-lg transition-all"
                : "hover:underline hover:underline-offset-4 cursor-pointer active:underline-offset-8 md:p-2 rounded-lg transition-all"
            }
          >
            <li className="">Våra fastigheter</li>
          </NavLink>
          <NavLink
            to="/ledigt"
            className={({ isActive }) =>
              isActive
                ? "underline underline-offset-4 hover:underline hover:underline-offset-4 cursor-pointer active:underline-offset-8 md:p-2 rounded-lg transition-all"
                : "hover:underline hover:underline-offset-4 cursor-pointer active:underline-offset-8 md:p-2 rounded-lg transition-all"
            }
          >
            <li className="">Ledigt</li>
          </NavLink>
          <NavLink
            to="/stadning"
            className={({ isActive }) =>
              isActive
                ? "underline underline-offset-4 hover:underline hover:underline-offset-4 cursor-pointer active:underline-offset-8 md:p-2 rounded-lg transition-all"
                : "hover:underline hover:underline-offset-4 cursor-pointer active:underline-offset-8 md:p-2 rounded-lg transition-all"
            }
          >
            <li className="">Städservice</li>
          </NavLink>
          <NavLink
            to="/kontakt"
            className={({ isActive }) =>
              isActive
                ? "underline underline-offset-4 hover:underline hover:underline-offset-4 cursor-pointer active:underline-offset-8 md:p-2 rounded-lg transition-all"
                : "hover:underline hover:underline-offset-4 cursor-pointer active:underline-offset-8 md:p-2 rounded-lg transition-all"
            }
          >
            <li className="">Kontakt</li>
          </NavLink>
        </ul>
      </div>
    ) : (
      <div className="flex h-10">
        <MenuMobile />
      </div>
    );

  return (
    <>
      <div className="mb-[20px]">
        {menu}
        <div className="md:pb-2 xl:h-[120px] shadow-md bg-orange-50 bg-opacity-50 flex justify-center items-center">
        <NavLink
            to="/">
          <img
            src={logo}
            alt="logo"
            className="max-h-[80px] md:max-h-[110px] p-2"
          />
          </NavLink>
        </div>
      </div>
    </>
  );
}
