import bg from "../../assets/cleaning.jpg";

import Container from "../../UI/Container";

export default function Stadning() {
  return (
    <>
      <Container>
        <div className="flex flex-col md:flex-row justify-center gap-10">
          <img src={bg} width={600} />
          <div className="rounded-lg p-4">
            <h2 className="text-xl mb-8 mt-8 font-semibold font-web tracking-wide uppercase">Städservice</h2>
            <div>
              <div>
                Information om städverksamheten hittar du här... <br></br>så småning om!
              </div>
              <div className="w-[40%] m-auto p-8 font-thin">Håll utkik!</div>
            </div>
          </div>
        </div>
        <div className="h-[21vh]"></div>
      </Container>
    </>
  );
}
