export default function LedigtInfoModal(props) {
  return (
    <>
      <div
        id="backdrop"
        className="bg-black bg-repeat-x bg-opacity-50 w-[100vw] h-[100vh] top-0 left-0 fixed"
        onClick={() => props.setIsModalOpen(false)}
      >
        <div className="animate-fadein w-[80vw] max-h-[80vh] xl:max-w-[1000px] xl:max-h-[85vh] xl:px-8 md:min-w-[90vw] md:max-h-[80vh] md:px-4 py-4 z-10 overflow-y-scroll xl:border-8 border-4 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] xl:translate-y-[-50%] md:translate-y-[-60%] bg-white rounded-lg">
          <div className="sticky top-0 flex justify-end">
            <button
              className="btn btn-gray"
              onClick={() => props.setIsModalOpen(false)}
            >
              Stäng
            </button>
          </div>
          <div className="flex justify-center">
            <div className="w-fit flex flex-col justify-center items-center gap-8">
              {props.images.map((img, index) => (
                <div key={index}>
                  <img
                    src={img}
                    alt={img}
                    
                    className="max-h-[780px] xl:max-h-[1000px]"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
